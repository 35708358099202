import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export function toTitleCase(str) {
  if (!str) return str;
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function groupIt(arr, key, firstLetter = true) {
  return arr && Array.isArray(arr)
    ? arr.reduce((store, arrObj) => {
        const letter = arrObj[key] ? (firstLetter ? arrObj[key].charAt(0) : arrObj[key]) : "?";
        const keyStore =
          store[letter] || // Does it exist in the object?
          (store[letter] = []); // If not, create it as an empty array
        keyStore.push(arrObj);

        return store;
      }, {})
    : {};
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export function calculatePercentCompletion(levels, currentScore) {
  let percentCompletion = [];

  for (let i = 0; i < levels.length; i++) {
    const level = levels[i];
    const progress = Math.max(0, Math.min(currentScore, level.number)) / level.number;
    percentCompletion.push(progress);
    currentScore -= level.number;
  }

  return percentCompletion;
}

// create a method that will return a number formatted into the correct currency
export function formatCurrency(num, currency) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(num);
}

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const formatMinutes = (minutes: number) => {
  const duration = dayjs.duration(minutes, "minutes");
  const hours = duration.hours();
  const mins = duration.minutes();
  return `${hours ? `${hours}h` : ""} ${mins ? `${mins}m` : ""}`.trim();
};
